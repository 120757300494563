<template>
  <div class="TeamOrderList">
    <div class="page-item" v-for="item in teamList" :key="item.chnlOrderId">
      <div class="img">
        <div class="page-icon withdraw"></div>
      </div>
      <div class="info f34">
        <p><strong>{{ item.remark }}</strong></p>
        <p>
          <span>订单号: {{ item.chnlOrderId }} </span>
          <span class="mainColor" @click="copyText(item.chnlOrderId)"> 复制</span>
        </p>
        <p>订单分佣: ￥{{ item.profit }}元</p>
        <p>消费时间: {{ item.accountTime | timeFilter }}</p>
      </div>
    </div>
    <van-divider>{{ loadingText }}</van-divider>
  </div>
</template>

<script>
import { mallApi } from '@/api'
import { dateFormat } from '@/utils/utils'

export default {

  name: 'TeamOrderList',
  data() {
    return {
      teamList: [],
      page: 1,
      pageSize: 10,
      flag: false,
      loadingText: '加载中'
    }
  },
  filters: {
    timeFilter(val) {
      return dateFormat(new Date(val))
    }
  },
  created() {
    this.getTeamOrderList()
  },
  mounted() {
    window.addEventListener('scroll', this.scrollListener)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    getTeamOrderList() {
      let params = {
        trxCode: 'XF917',
        type: 'time',
        page: this.page,
        pageSize: this.pageSize
      }
      mallApi(params).then(res => {
        if (res.teamMsgs.length < this.pageSize) {
          this.flag = true
          this.loadingText = '没有更多了'
        } else {
          this.flag = false
          this.loadingText = '上拉加载'
        }
        this.teamList = this.teamList.concat(res.teamMsgs)
      })
    },

    // 滚动监听
    scrollListener() {
      //总高度=滚动条滚动的高度+cliebtHeight（要是想不到底部就加载数据可以写成）
      //offsetHeight +clientHeight>=scrollHeight-n (n是任意数字)
      const clientHeight = document.documentElement.clientHeight
      const scrollHeight = document.body.scrollHeight
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (clientHeight + scrollTop >= scrollHeight - 100) {
        if (this.flag === false) {
          this.flag = true
          this.page = this.page + 1
          this.getTeamOrderList()
        }
      }
    },

    //复制订单号
    copyText(val) {
      this.$copyText(val).then((res) => {
        if (res.text) {
          this.$toast.success('复制成功')
        } else {
          this.$toast.fail('复制失败')
        }
      })
    },
  }
}
</script>

<style lang='less' scoped>
img {
  width: 100%;
}

.page-item {
  display: flex;
  align-items: center;
}

.img {
  margin-right: 12px;
}

.page-icon.withdraw {
  width: 54px;
  height: 54px;
  background-position: -451px -75px;
}

.info {
  color: #666;

  >p {
    margin-bottom: 4px;
  }
}</style>