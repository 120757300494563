<template>
  <div class="app-contianer">
    <div class="ourTeam-people page-item mainBgColor white">
      <ul class="team-number">
        <li>
          <div class="f40"><strong>{{( parseInt(cusmsg.first) + parseInt(cusmsg.second) ) || 0}}</strong></div>
          <div class="margin-t1">团队总人数</div>
        </li>
        <li>
          <div class="f40"><strong>{{cusmsg.orderNum || 0}}</strong></div>
          <div class="margin-t1">团队总订单</div>
        </li>
        <li>
          <div class="f40"><strong>{{cusmsg.todayOrder || 0}}</strong></div>
          <div class="margin-t1">团队今日订单</div>
        </li>
      </ul>
      <ul class="team-number">
        <li>
          <div class="f40"><strong>{{cusmsg.first || 0}}</strong></div>
          <div class="margin-t1">直推人数</div>
        </li>
        <li>
          <div class="f40"><strong>{{cusmsg.second || 0}}</strong></div>
          <div class="margin-t1">间推人数</div>
        </li>
        <li>
          <div class="f40"><strong>{{cusmsg.todayProfit || 0}}</strong></div>
          <div class="margin-t1">今日付款佣金</div>
        </li>
      </ul>
      <div class="team-number2">
        <div class="team-type">
          <div class="typelist" :class="listType === 'user'? 'mainBgColor':''" @click="listType = 'user'">
            好友列表
          </div>
          <div class="typelist" :class="listType === 'order'? 'mainBgColor':''" @click="listType = 'order'">
            团队订单列表</div>
        </div>
      </div>
    </div>

    <!-- 推广好友列表 -->
    <div v-show="listType === 'user'">
      <div class="search-box">
        <van-search v-model="orderlist.keyword" shape="round" background="transparent" placeholder="请输入搜索ID"
          @search="seachslist()" />
      </div>

      <ul class="ourTeam ">
        <li class="label" :class="orderlist.teamType === 'first'?'active':''" @click="changekey('first')">直推好友
        </li>
        <li class="label" :class="orderlist.teamType === 'second'?'active':''" @click="changekey('second')">间推好友
        </li>
      </ul>
      <directlyList :list="list" :text="loadingtext" :icon="icon"></directlyList>
    </div>

    <!-- 团队订单列表 -->
    <div v-if="listType === 'order'">
      <TeamOrderList />
    </div>
  </div>
</template>

<script>
import directlyList from './compent/directlyList'
import TeamOrderList from './compent/TeamOrderList.vue'
import { mallApi } from '@/api'
export default {
  name: 'oueTeam',
  components: { directlyList, TeamOrderList },
  data() {
    return {
      type: 0,
      key: 0,
      cusmsg: {},
      listType: 'user',
      orderlist: {
        trxCode: 'XF908',
        type: 'time',
        teamType: 'first',
        page: 1,
        pageSize: 50,
        keyword: '',
      },
      list: [],
      loadingtext: '',
      icon: 'replay',
    }
  },
  created() {
    this.listType = this.$route.query.listType || 'user'
    this.getteamList()
  },
  mounted() {
    window.addEventListener('scroll', this.scrollListener)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    //改变间推直推
    changekey(val) {
      this.orderlist.teamType = val
      this.orderlist.page = 1
      this.orderlist.pageSize = 50
      this.list = []
      this.icon = 'replay'
      this.getteamList()
    },
    // 获得团队人数
    seachslist() {
      this.orderlist.page = 1
      this.orderlist.pageSize = 50
      this.list = []
      this.icon = 'replay'
      this.getteamList()
    },
    getteamList() {
      this.loadingtext = '加载中'
      mallApi(this.orderlist).then((res) => {
        // console.log('团队', res)
        this.cusmsg = res.custMsg
        if (res.custMsgs) {
          if (res.custMsgs.length < 50) {
            this.loadingtext = '没有更多了'
            this.icon = ''
          } else {
            this.flag = false
            this.loadingtext = '上拉加载'
          }
          res.custMsgs.forEach((item) => {
            this.list.push(item)
          })
        }
      })
    },
    // 滚动监听
    scrollListener() {
      //总高度=滚动条滚动的高度+cliebtHeight（要是想不到底部就加载数据可以写成）
      //offsetHeight +clientHeight>=scrollHeight-n (n是任意数字)
      const clientHeight = document.documentElement.clientHeight
      const scrollHeight = document.body.scrollHeight
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (clientHeight + scrollTop >= scrollHeight - 100) {
        if (this.flag === false) {
          this.flag = true
          this.orderlist.page = this.orderlist.page + 1
          this.getteamList()
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.ourTeam-people {
  border-radius: 10px;
  padding: 12px 0;
  text-align: center;

  .team-number {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 12px 0;
  }
  .team-number2 {
    .team-type {
      height: 5.4vh;
      background: #ffffff;
      border-radius: 10px;
      border: 3px solid #ffffff;
      display: flex;
      margin: 0 24px;

      .typelist {
        width: 50%;
        border-radius: 10px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 3vw;
        color: #333;
      }
      .typelist.mainBgColor {
        color: #fff;
      }
    }
  }
}
.search-box {
  width: 100%;
}
.van-search__content {
  background-color: #fff;
  padding: 4px 12px;
}

.ourTeam {
  padding: 12px;
  font-size: 3.8vw;
  display: flex;
  justify-content: space-around;

  li {
    position: relative;
    color: #666;
  }
  li.active {
    font-weight: bold;
    color: #222;
  }
  li.active::after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 28px;
    margin-left: -14px;
    height: 4px;
    border-radius: 2px;
    bottom: -6px;
    left: 50%;
  }
}
</style>
