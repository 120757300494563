<template>
  <div style="width: 100%">
    <div class="directlylist page-item" v-for="(item, index) in list" :key="index">
      <div class="directlylist-person">
        <div class="person-img">
          <img v-if="item.imgUrl" :src="item.imgUrl" />
          <img v-else src="../../../assets/user/photo.png" alt="">
        </div>
        <div class="person-text">
          <div class="person-title">{{item.nickName || '用户'+item.custId}}</div>
          <div class="person-type">
            <img src="../../../assets/user/notselect.png" v-if="item.vipPlus === 0">
            <img src="../../../assets/user/alreadyselect.png" v-if="item.vipPlus === 1">
          </div>
        </div>
        <div class="person-time">
          <div class="time-title">{{item.createTime}}</div>
        </div>
      </div>
      <div class="directlylist-information">
        <div class="information">
          <div class="margin-tb2">用户ID</div>
          <div class="mainColor">{{item.custId}}</div>
        </div>
        <div class="information">
          <div class="margin-tb2">团队人数</div>
          <div class="mainColor">{{item.teamNum}}</div>
        </div>
        <div class="information">
          <div class="margin-tb2">订单数量</div>
          <div class="mainColor">{{item.orderNum}}</div>
        </div>
      </div>
    </div>
    <van-divider>{{text}}<i :class="icon"></i></van-divider>
  </div>
</template>

<script>
export default {
  name: 'directlyList',
  props: {
    list: {
      type: Array,
      required: true,
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
}
</script>

<style lang="less" scoped>
.directlylist {
  .directlylist-person {
    width: 100%;
    display: flex;
    align-items: center;

    .person-img {
      margin-right: 12px;
      img {
        width: 10vw;
        border-radius: 50%;
      }
    }

    .person-text {
      flex: 1;

      .person-title,
      .person-type {
        width: 100%;
        height: 60%;
        font-size: 3.5vw;
        font-weight: bold;
        color: #333333;
      }

      .person-type {
        margin-top: 1vw;

        img {
          width: 16vw;
          // height: 5vw;
        }
      }
    }

    .person-time {
      .time-title {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 3.2vw;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #cccccc;
      }
    }
  }

  .directlylist-information {
    padding-top: 2vw;
    display: flex;
    justify-content: space-between;

    .information {
      width: 25%;
      text-align: center;
      font-size: 3.2vw;
      color: #999;
    }
  }
}
</style>
